import { createWithEqualityFn } from 'zustand/traditional';
import { createJSONStorage, persist } from 'zustand/middleware';
import { AppConfig, AppConfigModel } from './types/appConfig';
import { shallow } from 'zustand/shallow';

const useAppStore = createWithEqualityFn<AppConfigModel>()(
    persist(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (set, get) => {
            const initialState: AppConfigModel = {
                config: undefined,
                setConfig: (config: AppConfig | undefined): void => set((state) => ({ ...state, config: config })),
            };
            return initialState;
        },
        {
            name: 'app-store',
            version: 1,
            storage: createJSONStorage(() => window.localStorage),
        },
    ),
    shallow,
);

export { useAppStore };
