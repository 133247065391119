import AppRoutes from './Routes';
import { appEnvironment } from '@components/appEnvironment';
import { AppFooter } from '@components/AppFooter/AppFooter';
import { BrowserRouter } from 'react-router-dom';
import { EnvironmentHeader } from '@components/EnvironmentHeader/';
import { HeaderAccessNavigation } from '@components/HeaderAccessNavigation/HeaderAccessNavigation';
import { SiteHeader } from '@components/SiteHeader/SiteHeader';
import { useEffect, useState } from 'react';
import { OidcProvider } from '@axa-fr/react-oidc';
import { useGetConfig } from '@config/configuration';
import OidcCallback, { SessionExpired, Authenticating } from '@components/Oidc';
import { OrgDataLoader } from '@components/OrgDataLoader/OrgDataLoader';
import { AppAbilityType, buildAbilityFor } from 'config/ability';
import { AbilityContext } from '@components/Can/can';
import { useOrgStore } from '@stores/OrgStore';
import { shallow } from 'zustand/shallow';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { FlagsProvider } from 'flagged';
import { MantineProvider } from '@mantine/core';

type SiteContentProps = {
    environment: appEnvironment;
    version: string;
};

const SiteContent: React.FC<SiteContentProps> = ({ environment, version }): JSX.Element => {
    const [ability, setAbility] = useState<AppAbilityType>(buildAbilityFor(null));
    const { selectedOrganisation } = useOrgStore(
        (state) => ({ selectedOrganisation: state.selectedOrganisation }),
        shallow,
    );

    useEffect(() => {
        if (selectedOrganisation !== undefined) {
            setAbility(buildAbilityFor(selectedOrganisation));
        } else {
            setAbility(buildAbilityFor(null));
        }
    }, [selectedOrganisation]);

    return (
        <>
            <OrgDataLoader />
            <AbilityContext.Provider value={ability}>
                <MantineProvider>
                    <BrowserRouter future={{
                        v7_relativeSplatPath: true,
                        v7_startTransition:true,
                    }}>
                        <HeaderAccessNavigation />
                        <EnvironmentHeader environment={environment} text={version} />
                        <div className="container-fluid d-flex flex-column min-vh-100">
                            <SiteHeader />
                            <main role="main" className="xs:tw--mr-4 xs:tw--ml-4">
                                <div id="qg-content">
                                    <ConfirmDialog />
                                    <AppRoutes />
                                </div>
                            </main>
                            {/* <AppDebug /> */}
                            <AppFooter />
                        </div>
                    </BrowserRouter>
                </MantineProvider>
            </AbilityContext.Provider>
        </>
    );
};

const InnerApp: React.FC = () => {
    const appConfig = useGetConfig();
    const isConfigReady = appConfig?.isReady;
    if (!isConfigReady) {
        return <EmptyAppShell />;
    }

    return (
        <OidcProvider
            configuration={appConfig.oidcConfig}
            authenticatingComponent={Authenticating}
            sessionLostComponent={SessionExpired}
            callbackSuccessComponent={OidcCallback}
        >
            <FlagsProvider features={appConfig.features}>
                <SiteContent environment={appConfig.environment} version={appConfig.version} />
            </FlagsProvider>
        </OidcProvider>
    );
};

const EmptyAppShell = (): JSX.Element => {
    return (
        <div className="container-fluid d-flex flex-column min-vh-100">
            <SiteHeader withAuth={false} />
            <main role="main"></main>
            <AppFooter />
        </div>
    );
};

export default InnerApp;
