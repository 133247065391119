import React, { Suspense } from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import './styles/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from '@components/Fallback/Fallback';

const renderApp = (rootElement: HTMLElement) => {
    const root = createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <ErrorBoundary FallbackComponent={Fallback}>
                <Suspense fallback={<div>Loading...</div>}>
                    <App />
                </Suspense>
            </ErrorBoundary>
        </React.StrictMode>,
    );
};

// Ensure DOM is ready and root exists
const initialize = (): void => {
    const rootContainer = document.getElementById('root');
    
    if (!rootContainer) {
      throw new Error('Root element not found - check if element with id "root" exists');
    }
    
    renderApp(rootContainer);
  };
  

  // Only run in browser environment
if (typeof window !== 'undefined') {
    initialize();
}