/**
 * Adds a trailing slash to a URL if it is missing.
 *
 * @param url - The URL to be processed.
 * @returns The URL with a trailing slash if it was missing.
 */
const addTrailingSlashIfMissing = (url: string): string => {
    const trimmedUrl = url.trim();
    if (trimmedUrl.length === 0) {
        return '/';
    }
    return trimmedUrl.endsWith('/') ? trimmedUrl : `${trimmedUrl}/`;
};

export { addTrailingSlashIfMissing };
