import { routes } from '@pages/routes';
import UploadIcon from '@assets/images/icons/upload-vcd.png';
import PersonIcon from '@assets/images/icons/manage-users.png';
import FormsIcon from '@assets/images/icons/contact-form.png';
import NotificationIcon from 'assets/images/icons/notification.png';
import { HomeCard, HomeCardSkeleton } from '@features/homepage';
import { useOrgStore } from '@stores/OrgStore';
import { shallow } from 'zustand/shallow';
import { useGetConfig } from '@config/configuration';
import { Feature } from 'flagged'; //feature toggling.
import { useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';

function UserActionCards(): JSX.Element {
    const appConfig = useGetConfig();
    const { selectedRegistration, selectedOrganisation, isOrgDataLoading } = useOrgStore(
        (state) => ({
            selectedRegistration: state.selectedRegistration,
            selectedOrganisation: state.selectedOrganisation,
            isOrgDataLoading: state.isOrgDataLoading,
        }),
        shallow,
    );

    const canManageUsers = useMemo(() => {
        const permissionRequired = 'admin';
        return (
            selectedRegistration?.permissions?.some((perm) => perm.action === permissionRequired && perm.allowed) ||
            selectedOrganisation?.permissions?.some((perm) => perm.action === permissionRequired && perm.allowed) ||
            false
        );
    }, [selectedRegistration, selectedOrganisation]);

    const showCards = !isOrgDataLoading;

    const cardData = [
        {
            key: 'documents',
            imageUrl: UploadIcon,
            route: routes.DOCUMENTS,
            title: 'Documents',
            description: 'Upload documents.',
            condition: showCards && selectedOrganisation,
        },
        {
            key: 'notifications',
            imageUrl: NotificationIcon,
            route: routes.NOTIFICATIONS,
            title: 'Notifications',
            description: 'Notifications for village operators.',
            condition: showCards,
        },
        {
            key: 'forms',
            imageUrl: FormsIcon,
            link: 'https://www.business.qld.gov.au/industries/service-industries-professionals/housing-accommodation/operating-retirement-village/forms',
            title: 'Forms for retirement village operators',
            description: 'Download forms for retirement village operators.',
            condition: showCards,
        },
        {
            key: 'vcd',
            imageUrl: FormsIcon,
            localLink: `${appConfig.vcdPortalRootUrl}village-comparison-document/org/${selectedOrganisation?.id}`,
            title: 'VCD',
            description: 'Village Comparison Document',
            condition: showCards,
            isFeature: true,
            featureName: 'vcdFeature',
        },
        {
            key: 'manageUsers',
            imageUrl: PersonIcon,
            route: routes.MANAGE_USERS,
            title: 'Manage users',
            description: 'Manage user accounts in your organisation.',
            condition: showCards && canManageUsers,
        },
    ];

    return (
        <div className="tw-flex tw-justify-center">
            <div className="tw-p-6 tw-grid tw-grid-cols-1 sm:tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-3 tw-gap-5">
                <AnimatePresence>{!showCards && <HomeCardSkeleton />}</AnimatePresence>
                <AnimatePresence>
                    {cardData.map((card) => {
                        if (card.condition) {
                            return card.isFeature ? (
                                <Feature key={card.key} name={card.featureName}>
                                    <HomeCard
                                        imageUrl={card.imageUrl}
                                        route={card.route}
                                        link={card.link}
                                        localLink={card.localLink}
                                        title={card.title}
                                        description={card.description}
                                    />
                                </Feature>
                            ) : (
                                <HomeCard
                                    key={card.key}
                                    imageUrl={card.imageUrl}
                                    route={card.route}
                                    link={card.link}
                                    localLink={card.localLink}
                                    title={card.title}
                                    description={card.description}
                                />
                            );
                        }
                        return null;
                    })}
                </AnimatePresence>
            </div>
        </div>
    );
}

export { UserActionCards };
